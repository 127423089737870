import {useState, useEffect} from 'react';

/**
 * A hook that detects whether the user has an adblocker enabled
 * @returns {boolean} True if adblock is detected, false otherwise
 */
const useAdBlockDetection = (): boolean => {
  const [adBlockDetected, setAdBlockDetected] = useState<boolean>(false);

  useEffect(() => {
    const checkAdBlocker = async (): Promise<void> => {
      try {
        // Try to load an ad script
        const testScript: HTMLScriptElement = document.createElement('script');
        testScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        testScript.setAttribute('async', 'true');
        document.head.appendChild(testScript);

        const onScriptError = () => {
          setAdBlockDetected(true);
        };

        testScript.onerror = onScriptError;

        if (testScript.parentNode) {
          testScript.parentNode.removeChild(testScript);
        }
      } catch (error) {
        setAdBlockDetected(true);
      }
    };

    checkAdBlocker();
  }, []);

  return adBlockDetected;
};

export default useAdBlockDetection;
