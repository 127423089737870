import {memo} from 'react';
import {useI18n} from '../../../hooks/use-i18n';
import {ReactComponent as IconAnonFun} from '../../../assets/svg/anonfun-logo-with-name.svg';

const AdultsOnly = () => {
  const {t} = useI18n();
  return (
    <div className="flex w-screen h-screen justify-center items-center bg-[#E8E8E8]">
      <div className="flex flex-col gap-16 lg:gap-24 justify-center items-center">
        <IconAnonFun />
        <h3 className="lg:text-base text-sm text-black-dark/60">{t('adults-only-page-text')}</h3>
      </div>
    </div>
  );
};

export default memo(AdultsOnly);
