import {createRef, memo, useCallback, useImperativeHandle} from 'react';
import {AgeVerificationModalRef} from '../types';
import {Modal} from '../../../ui/Modal';
import {useModalState} from '../../../hooks/use-modal-state';
import {ReactComponent as IconAnonFun} from '../../../assets/svg/anonfun-logo-with-name.svg';
import {useI18n} from '../../../hooks/use-i18n';
import ageVerificationModalStorage from '../storage';
import {Link} from 'react-router-dom';

const ageVerificationModalRef = createRef<AgeVerificationModalRef>();

// Use this module to limit access to the ref
export const AgeVerificationModalHandler: {openIfNeeded: () => void} = (() => {
  return {
    openIfNeeded: () => {
      if (ageVerificationModalStorage.hasConfirmed()) {
        return;
      }
      ageVerificationModalRef.current?.open?.();
    },
  };
})();

const AgeVerificationModal = () => {
  const {isOpen, show, hide} = useModalState();
  const {t} = useI18n();

  useImperativeHandle(ageVerificationModalRef, () => ({
    open: show,
  }));

  const handleConfirm = useCallback(() => {
    ageVerificationModalStorage.confirm();
    hide();
  }, [hide]);

  const handleDecline = useCallback(() => {
    ageVerificationModalStorage.decline();
    hide();
  }, [hide]);

  return (
    <Modal
      isOpen={isOpen}
      hide={hide}
      disableClose={true}
      className={
        'flex justify-center items-center w-full p-20 pt-32 text-center lg:max-w-650 lg:p-64 lg:pt-64 gap-24 lg:gap-32 self-auto rounded-b m-20 lg:m-0'
      }
      backdropClassName="backdrop-blur-[8px] bg-black/30"
    >
      <IconAnonFun />
      <div className="flex flex-col gap-16">
        <h2 className="font-extrabold text-[2.6rem] lg:text-2xl text-black">{t('anonfun-adult-modal-title')}</h2>
        <p className="text-black-dark/60 text-sm lg:text-base">{t('anonfun-adult-modal-desc')}</p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:flex-row w-full gap-16">
        <button type="button" className="btn btn--primary btn--md w-full" onClick={handleConfirm}>
          {t('anonfun-adult-modal-confirmation-btn-title')}
        </button>
        <Link
          to={'/adults-only'}
          type="button"
          className="btn btn--light-primary text-gradient-primary btn--md w-full"
          onClick={handleDecline}
        >
          {t('anonfun-adult-modal-decline-btn-title')}
        </Link>
      </div>
    </Modal>
  );
};

export default memo(AgeVerificationModal);
