import LockManager from './util/lock/lock-manager';
import InMemoryLock from './util/lock/in-memory-lock/in-memory-lock';
import TrafficAnalyticsService from './services/analytics-service/traffic-analytics-service';
import {api} from './http/api';
import TranslationsService from './services/translation-service/translations-service';
import UserAgentService from './services/user-agent-service/user-agent-service';
import BroadcastLogout from './broadcast/broadcast-logout';
import BroadcastLogin from './broadcast/broadcast-login';
import PostbackService from './services/postback-service/postback-service';
import PosthogService from './services/posthog-service/posthog-service';

class DependencyInjector {
  private readonly _inMemoryLockManager: LockManager;
  private readonly _trafficAnalyticsService: TrafficAnalyticsService;
  private readonly _translationService: TranslationsService;
  private readonly _userAgentService: UserAgentService;
  private readonly _broadcastLogout: BroadcastLogout;
  private readonly _broadcastLogin: BroadcastLogin;
  private readonly _postbackService: PostbackService;
  private readonly _posthogService: PosthogService;

  constructor() {
    this._userAgentService = new UserAgentService();
    // Lock manager
    this._inMemoryLockManager = new LockManager(() => new InMemoryLock());
    // Traffic analytics service
    this._trafficAnalyticsService = new TrafficAnalyticsService(api, this._inMemoryLockManager);
    this._translationService = new TranslationsService();
    this._broadcastLogout = new BroadcastLogout();
    this._broadcastLogin = new BroadcastLogin();
    this._postbackService = new PostbackService(this._trafficAnalyticsService);
    this._posthogService = new PosthogService();
  }

  get inMemoryLockManager() {
    return this._inMemoryLockManager;
  }

  get trafficAnalyticsService() {
    return this._trafficAnalyticsService;
  }

  get translationService() {
    return this._translationService;
  }

  get userAgentService() {
    return this._userAgentService;
  }

  get broadcastLogout() {
    return this._broadcastLogout;
  }

  get broadcastLogin() {
    return this._broadcastLogin;
  }

  get postbackService() {
    return this._postbackService;
  }

  get posthogService() {
    return this._posthogService;
  }
}

export const di = new DependencyInjector();
