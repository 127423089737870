import {getUser} from '../../redux/helpers/get-user';
import {PosthogEvent} from '../../shared/posthog-event';
import {isDev} from '../../util/helpers/is-dev';
import {IPosthogService} from './i-posthog-service';

class PosthogService implements IPosthogService {
  capture(eventName: PosthogEvent, params?: Record<string, string>) {
    const result = window?.posthog?.capture(eventName, params);
    // Log results in dev
    if (isDev()) console.log(result);
  }

  captureCustomerOnly(eventName: PosthogEvent, params?: Record<string, string>) {
    const isCustomer = getUser()?.type === 'customer';
    if (isCustomer) {
      return this.capture(eventName as PosthogEvent, params);
    }
  }
}

export default PosthogService;
