import {FormEvent, useState} from 'react';
import EmailInput from '../../EmailInput';
import SubmitButton from '../../SubmitButton';
import CreateAccountBottomDescription from './CreateAccountBottomDescription';
import {isValidEmail} from '../../../../../shared/is-valid-email';
import DEV_SkipEmailVerificationCheckbox from '../../SkipEmailVerificationCheckbox';

interface Props {
  email: string;
  setEmail: (val: string) => void;
  onCreateAccountClick: () => void;
  skipEmailVerification: boolean;
  setSkipEmailVerification: (val: boolean) => void;
}

const CreateAccountForm = (props: Props) => {
  const {email, setEmail, skipEmailVerification, setSkipEmailVerification, onCreateAccountClick} = props;
  const [isEmailIncorrect, setIsEmailIncorrect] = useState(false);
  const disabled = isEmailIncorrect || !isValidEmail(email);
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    onCreateAccountClick();
  };

  return (
    <form className={'flex flex-col'} onSubmit={onSubmit}>
      <EmailInput
        email={email}
        setEmail={setEmail}
        setEmailIncorrect={setIsEmailIncorrect}
        checkEmailExistence={true}
      />
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <DEV_SkipEmailVerificationCheckbox
        skipEmailVerification={skipEmailVerification}
        setSkipEmailVerification={setSkipEmailVerification}
      />
      <SubmitButton titleKey={'create-account'} disabled={disabled} />
      <CreateAccountBottomDescription />
    </form>
  );
};

export default CreateAccountForm;
