class AgeVerificationModalStorage {
  private readonly _key: string = 'age-verification';

  hasConfirmed = () => {
    return localStorage.getItem(this._key) === 'confirmed';
  };

  confirm = () => {
    return localStorage.setItem(this._key, 'confirmed');
  };

  decline = () => {
    return localStorage.setItem(this._key, 'declined');
  };
}

const ageVerificationModalStorage = new AgeVerificationModalStorage();
export default ageVerificationModalStorage;
