import {store} from '../store';
import {IUserClient} from '../../shared/user';
import {UserType} from '../../shared/user-type';

export const getUser = (): Partial<IUserClient> => {
  return store.getState().user.data;
};

export const getUserId = (): string => {
  return getUser()?.userId ?? '';
};

export const getUserType = (): UserType | undefined => {
  return getUser()?.type;
};
