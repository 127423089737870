import {isDev} from '../../../util/helpers/is-dev';

interface Props {
  skipEmailVerification: boolean;
  setSkipEmailVerification: (val: boolean) => void;
}

const DEV_SkipEmailVerificationCheckbox = (props: Props) => {
  const {skipEmailVerification, setSkipEmailVerification} = props;

  if (!isDev()) {
    return null;
  }

  return (
    <label className="flex cursor-pointer items-center gap-16 font-bold mb-10">
      <input
        className="radio shrink-0"
        type="checkbox"
        checked={skipEmailVerification}
        onChange={(e) => setSkipEmailVerification(e.target.checked)}
      />
      <div>skip email verification</div>
    </label>
  );
};

export default DEV_SkipEmailVerificationCheckbox;
